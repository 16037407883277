import React from 'react'
import styled from 'styled-components'

import EmailWithIcon from './EmailWithIcon'
import PhoneWithIcon from './PhoneWithIcon'

const ContactItem = ({name, email, phone}) => {
  return (
    <Wrapper>
      <Name>{name}</Name>
      <EmailWithIcon email={email}/>
      <PhoneWithIcon phone={phone}/>
    </Wrapper>
  )
}
  
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 32px 0;
  padding: 0 24px;
  max-width: 960px;
`
const Name = styled.h4`
  @media screen and (max-width: 768px) {
    font-size: 18px;
  };
`
export default ContactItem