import React from 'react'
import styled from 'styled-components'

import CMSImage from '../UI/CMSImage'

const Benefit = ({page}) => {

  const getItems = (data) => {
    const itemsArray = []
    data.benefits && data.benefits.forEach((item, index) => {
      itemsArray.push(
        <Item key={index}>
					<div className="icon">
						<CMSImage
							svg={item.icon.svg}
							gatsbyImageData={item.icon?.gatsbyImageData}
							file={item.icon.file}
							alt={item.icon.title}
						/>
					</div>
          <div dangerouslySetInnerHTML={{
						__html: item.header.childMarkdownRemark.html,
					}}/>
          <div dangerouslySetInnerHTML={{
						__html: item.content.childMarkdownRemark.html,
					}}/>
        </Item>
      )
    })
    return itemsArray
  }

  return (
    <Items>
      {getItems(page)}
    </Items>

  )
}

const Items = styled.div`
	position: relative;
	z-index: 1;
	margin: 0 auto; 
	padding: 30px 0 80px 0px; 
	max-width: 1232px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	${'' /* margin-top: 120px; */}
	@media screen and (max-width: 1270px) {
		margin-left: 24px;
		margin-right: 24px;
	}
	@media screen and (max-width: 991px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px 20px;
    margin: 30px 24px;
	};
	@media screen and (max-width: 480px) {
    margin: 30px 20px;
	};

`
const Item = styled.div`
	width: 23%;
	@media screen and (max-width: 991px) {
		width: 100%;
	};

	img {
		height: 42px;
		width: auto;
	};
	h3 {
		font-size: 22px;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
      font-size: 18px
    };
	};
	h2 {
		font-size: 22px;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
      font-size: 18px
    };
	};
	p {
		margin: 10px 0;
    @media screen and (max-width: 768px) {
      font-size: 16px
    };

	}
`

export default Benefit