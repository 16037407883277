import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate } from 'gatsby'
import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';
import { FaChevronRight } from 'react-icons/fa'

const HeaderWithImage = ({page}) => {
	return (
		<ContentContainer>
			<Main>
				<MainGetRate>
					<div dangerouslySetInnerHTML={{
						__html: page.heading.childMarkdownRemark.html,
					}}/>
					<div className="subHeader" dangerouslySetInnerHTML={{
						__html: page.subheading.childMarkdownRemark.html,
					}}/>
					<div className="btnWrapper">
						<HomeButton
							height={BUTTON_SIZE.HEIGHT_L}
							padding={BUTTON_SIZE.PADDING_L}
							fontSize={BUTTON_SIZE.FONT_SIZE_L}
							background={BUTTON_COLOR.GREEN}
							backgroundHover={BUTTON_COLOR.GREEN_HOVER}
							color={BUTTON_COLOR.TEXT_COLOR_WHITE}
							text={page.becomeAccreditedLink.text}
							handleClick={() => navigate(page.becomeAccreditedLink.url)}
							margin='0'
						/>
						<GetScore href={page.singInLink.url} >
							<p>{page.singInLink.text}</p>
							<div className="icon">
								<FaChevronRight />
							</div>
						</GetScore>
					</div>
				</MainGetRate>
				<MainImg>
					<GatsbyImage image={page.headerImage?.gatsbyImageData} alt={page.headerImage?.title} />
				</MainImg>
			</Main>			
		</ContentContainer>
	)
}


const Main = styled.div`
	margin: 0 30px;
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};

`
const MainGetRate = styled.div`
	max-width: 586px;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	};
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};

	};
	.subHeader {
		font-size: 20px;
		@media screen and (max-width: 768px) {
			text-align: center;
		};
	};
	.btnWrapper {
		display: flex;
		flex-direction: row;
		padding: 40px 0;
		button {
			margin: 0;
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			button {
			margin: 0 auto;
			}
		}
	}
`
const MainImg = styled.div`
	width: 445px;
	border-radius: 50%;
	overflow: hidden;
	img {
		padding: 0 !important;
	}

	@media screen and (max-width: 991px) {
		max-width: 396px;
	};

	img {
		padding-right: 50px;
	}
	@media screen and (max-width: 768px) {
		margin: 30px 0;
		img {
		padding-right: 0;
	}

	};
	@media screen and (max-width: 480px) {
		max-width: 100%;
	};
`

const GetScore = styled.a`
	display: flex;
	align-items: center;
	margin-left: 30px;
	p {
		font-family: PoppinsBold;
		margin: 5px 0;
	};
	.icon {
		padding: 0 10px; 
		display: flex; 
		align-items: center;
	};
	@media screen and (max-width: 991px) {
		margin: 20px 0 0 0;
	}

`

export default HeaderWithImage
