import React from 'react'
import styled from 'styled-components'
import { BsTelephone } from 'react-icons/bs'

const PhoneWithIcon = ({phone}) => {
  return (
    <Wrapper>
      <div className="icon">
        <BsTelephone />
      </div>
      <p>{phone}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
  &:hover {
    cursor: pointer;
  }
	p {
		margin: 5px 0;
    text-decoration: underline;
	};
	.icon {
		padding: 0 10px; 
		display: flex; 
		align-items: center;
	};

	@media screen and (max-width: 768px) {
		justify-content: center;
	}
`
export default PhoneWithIcon