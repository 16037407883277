import React from 'react'
import styled from 'styled-components'

const ContactHeader = ({header, children}) => {
  return (
    <Wrapper>
      <Header>
        {header}
      </Header>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`
const Header = styled.h3`
  background: rgb(212 219 224);
  padding: 16px 24px;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  };

`

export default ContactHeader